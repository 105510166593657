import { Avatar, Card, Collapse, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material"
import { Fragment } from "react"
import { FaHelicopter, FaTractor, FaTrailer } from "react-icons/fa"
import { GiBus, GiTruck } from "react-icons/gi"
import { RiTruckFill } from "react-icons/ri"
import { ResponseProps1 } from "../../../pages/Histories/types"

type ListItemNotValuesProps = {
  index: number,
  indexSelected: number
  email: string
  item: ResponseProps1
  created_at: string
  setIndexSelected: (value: React.SetStateAction<number>) => void
}

export default function ListItemNotValues({ index, indexSelected, email, item, setIndexSelected, created_at }: ListItemNotValuesProps): JSX.Element {
  return (
    <Card key={`fiv-list-item-${index}`} style={{ marginTop: 20 }}>
      <ListItemButton onClick={() => { setIndexSelected(index) }}>
        <ListItemIcon>
          <Avatar>
            {
              item.type === "1"
                ? <FaTractor />
                : item.type === "2"
                  ? <FaHelicopter />
                  : item.type === "3"
                    ? <FaTrailer />
                    : item.type === "4"
                      ? <RiTruckFill />
                      : item.type === "5"
                        ? <div style={{ display: 'flex', flexDirection: 'column' }}><GiTruck /><GiBus /></div>
                        : <img width="20" height="20" src="https://img.icons8.com/material-rounded/96/harvester.png" alt="harvester" />
            }
          </Avatar>
        </ListItemIcon>
        <ListItemText
          primary={
            <Fragment>
              <Typography
                variant="body1"
                color="text.primary"
              >
                <strong>Modelo: </strong>{item.model}
              </Typography>
            </Fragment>
          }
          secondary={
            <Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body1"
                color="text.primary"
              >
                {`Consulta realizada por ${email} no dia ${created_at}`}
              </Typography>
            </Fragment>
          }
        />
      </ListItemButton>
      <Collapse in={index === indexSelected} timeout="auto" unmountOnExit>
        <List component="div" disablePadding key={`list-${index}`}>
          <ListItem sx={{ pl: 4 }} key={`list-item-${index}`}>
            <ListItemText
              primary={
                <Fragment>
                  <Typography
                    variant="body1"
                    color="text.primary"
                  >
                    <strong>Cod: </strong>{item.somos_code || item.codigo_fipe}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.primary"
                  >
                    <strong>Marca: </strong>{item.mounter}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.primary"
                  >
                    <strong>Modelo: </strong>{item.model}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.primary"
                  >
                    <strong>Ano: </strong>{item.year}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.primary"
                  >
                    <strong>Valor: </strong>
                    {item?.type === "5" ? '' : 'R$ '}
                    {item?.value !== undefined && item?.value !== null ? item.value.toLocaleString() : '-'}
                  </Typography>
                </Fragment>
              }
            />
          </ListItem>
          <Divider />
        </List>
      </Collapse>
    </Card>
  )
}