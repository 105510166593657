/* eslint-disable react-hooks/exhaustive-deps */
import Core from "../Core"
import { styled } from '@mui/system'
import { toast } from "react-toastify"
import colors from "../../styles/colors"
//@ts-ignore
import styles from "./styles.module.css"
import * as api from '../../services/api'
import { useAuth } from "../../hooks/useAuth"
import { GiTruck, GiBus } from "react-icons/gi"
import { useReactToPrint } from 'react-to-print'
import ShareIcon from '@mui/icons-material/Share'
import InfoPrice from "../../components/InfoPrice"
import CardButton from "../../components/CardButton"
import ArticleIcon from '@mui/icons-material/Article'
import useWindowSize from "../../hooks/useWindowSize"
import { ConsultProps, UserProps } from "../User/types"
import InfoPrice2 from "../../components/plateChassiInfo"
import { DefaultMessage } from "../../utils/defaultMessages"
import { useCallback, useEffect, useRef, useState } from "react"
import { FaHelicopter, FaTractor, FaTrailer, FaTruck } from 'react-icons/fa'
import { ModelResponseProps, ModelYearProps, MounterResponseProps, PriceResponseProps } from "./types"
import { Button, Chip, FormControl, Grid, MenuItem, Select, SelectChangeEvent, TextField, CircularProgress, Radio } from "@mui/material"

const colheitadeira = require("../../images/colheitadeira.png");
const colheitadeiraCinza = require("../../images/colheitadeiraCinza.png");
const colheitadeiraBranca = require("../../images/colheitadeiraBranca.png");
const colheitadeiraPreta = "https://img.icons8.com/material-rounded/96/harvester.png";

export default function Dashboard(): JSX.Element {
  const { user } = useAuth()
  const { width } = useWindowSize()
  const [smallCardButton, setSmallCardButton] = useState<boolean>(false)
  const [selectedValue, setSelectedValue] = useState<string | null>(localStorage.getItem("buttonState"));
  const [selectedValueTruckBus, setSelectedValueTruckBus] = useState<string | null>(localStorage.getItem("buttonStateTruckBus"));

  const [mounters, setMounters] = useState<undefined | MounterResponseProps>()
  const [models, setModels] = useState<undefined | ModelResponseProps>()
  const [years, setYears] = useState<undefined | ModelYearProps>()
  const [displayToCard, setDisplayToCard] = useState('none')
  const [isLoading, setIsLoading] = useState(true)

  const [product, setProduct] = useState<undefined | string>()
  const [mounter, setMounter] = useState<string>('mounter')
  const [model, setModel] = useState<string>('model')
  const [year, setYear] = useState<string>('year')

  const [price, setPrice] = useState<undefined | PriceResponseProps>()
  const [pricing, setPricing] = useState<undefined | ConsultProps>()
  const [pricingTruckBus, setPricingTruckBus] = useState<undefined | any>()
  const [userData, setUserData] = useState<UserProps>()
  const [companyBusTruck, setCompanyBusTruck] = useState<UserProps>()

  const [applied, setApplied]: [boolean, (newValue: boolean) => void] = useState(false)
  const [appliedTruckBus, setAppliedTruckBus]: [boolean, (newValue: boolean) => void] = useState(false)
  const [card, setCard]: [string, (newValue: string) => void] = useState("")
  const [placa, setPlaca]: [string, (newValue: string) => void] = useState("")
  const [chassi, setChassi]: [string, (newValue: string) => void] = useState("")
  const [checked, setChecked] = useState<boolean>(localStorage.getItem("buttonState") === "true" ? true : false)
  const [checkedTruckBus, setCheckedTruckBus] = useState<boolean>(localStorage.getItem("buttonStateTruckBus") === "true" ? true : false)

  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => {
      if (componentRef.current) {
        return componentRef.current;
      }
      return null;
    }
  });

  const CustomRadio = styled(Radio)(({ theme }) => ({
    '& .MuiSvgIcon-root': {
      color: 'white',
      background: 'white',
      borderRadius: '1rem',
      border: '3px solid white'
    },
    '&.Mui-checked .MuiSvgIcon-root': {
      color: 'rgb(0, 174, 239)',
      background: 'rgb(0, 174, 239)'
    },
  }));

  async function handleCardSelected(value: string) {

    if (userData?.plan_balance.tr_balance !== null) {
      if (value === '1' && userData?.plan_balance.tr_balance! < 1 && userData?.plan_balance.plan === '2') {
        return toast.error("Saldo insuficiente!")
      }
    }
    if (userData?.plan_balance.hp_balance !== null) {
      if (value === '2' && userData?.plan_balance.hp_balance! < 1 && userData?.plan_balance.plan === '2') {
        return toast.error("Saldo insuficiente!")
      }
    }
    if (userData?.plan_balance.sr_balance !== null) {
      if (value === '3' && userData?.plan_balance.sr_balance! < 1 && userData?.plan_balance.plan === '2') {
        return toast.error("Saldo insuficiente!")
      }
    }
    if (userData?.plan_balance.cr_balance !== null) {
      if (value === '4' && userData?.plan_balance.cr_balance! < 1 && userData?.plan_balance.plan === '2') {
        return toast.error("Saldo insuficiente!")
      }
    }
    if (userData?.plan_balance.cm_balance !== null) {
      if (value === '5' && userData?.plan_balance.cm_balance! < 1 && userData?.plan_balance.plan === '2') {
        return toast.error("Saldo insuficiente!")
      }
    }
    if (userData?.plan_balance.hv_balance !== null) {
      if (value === '6' && userData?.plan_balance.hv_balance! < 1 && userData?.plan_balance.plan === '2') {
        return toast.error("Saldo insuficiente!")
      }
    }
    setCard(value)
    setProduct(undefined)
    setMounter('mounter')
    setModel('model')
    setYear('year')
    setPrice(undefined)
    setProduct(value)
    setPricing(undefined)
    setChassi('')
    await getMounters(value)
    setSmallCardButton(true)
  }

  const products = user?.products

  const getMounters = async (product: string) => {
    if (product === '5') {
      await api.get(`table/search/trucks/`)
        .then((response: any) => {
          setIsLoading(false)
          setMounters(response.data)
        })
        .catch((error) => {
          toast.error(DefaultMessage.ERROR, {
            position: toast.POSITION.TOP_RIGHT
          })
        })
    }
    else {
      await api.get(`table/search/mounter/${product}/`)
        .then((response: any) => {
          setIsLoading(false)
          setMounters(response.data)
        })
        .catch((error) => {
          toast.error(DefaultMessage.ERROR, {
            position: toast.POSITION.TOP_RIGHT
          })
        })
    }
  }

  const handleChassiChange = async (e: SelectChangeEvent<string>) => {
    var regex = /^[a-zA-Z0-9\s\b]*$/;
    let value = e.target.value
    if (regex.test(value)) {
      setChassi(value)
    }
  }

  const changeSearchOption = async (event: string) => {
    setSelectedValue(event === 'brandModelYear' ? 'true' : 'false');
    setChecked(event === 'brandModelYear' ? true : false);
    localStorage.setItem("buttonState", JSON.stringify(checked));
    setApplied(!applied);
  }
  const changeSearchOptionTruckBus = async (event: string) => {
    setSelectedValueTruckBus(event === 'brandModelYearTruckBus' ? 'true' : 'false');
    setCheckedTruckBus(event === 'brandModelYearTruckBus' ? true : false);
    localStorage.setItem("buttonStateTruckBus", JSON.stringify(checkedTruckBus));
    setAppliedTruckBus(!applied);
  }

  const handleChangeMounter = async (e: SelectChangeEvent<string>) => {
    let value = e.target.value
    setMounter(value)
    if (value === "mounter") return
    await getModels(value)
  }

  const getModels = useCallback(async (mounterCode: string) => {
    if (product === '5') {
      await api.get(`table/search/trucks/${mounterCode}/`).then((response: any) => {
        setModels(response.data)
      }).catch((error) => {
        toast.error(DefaultMessage.ERROR, {
          position: toast.POSITION.TOP_RIGHT
        })
      })
    } else {
      await api.get(`table/search/codes/${product}/${mounterCode}/`).then((response: any) => {
        setModels(response.data)
      }).catch((error) => {
        toast.error(DefaultMessage.ERROR, {
          position: toast.POSITION.TOP_RIGHT
        })
      })
    }
  }, [product])

  const handleChangeModel = async (e: SelectChangeEvent<string>) => {
    let value = e.target.value
    setModel(value)
    if (value === "model") return
    await getYears(value)
  }

  const getYears = useCallback(async (modelCode: string) => {
    if (product === '5') {
      await api.get(`table/search/trucks/${mounter}/${modelCode}/`).then((response: any) => {
        setYears(response.data)
      }).catch((error) => {
        toast.error(DefaultMessage.ERROR, {
          position: toast.POSITION.TOP_RIGHT
        })
      })
    } else {
      await api.get(`table/search/model/${product}/${modelCode}/`).then((response: any) => {
        setYears(response.data)
      }).catch((error) => {
        toast.error(DefaultMessage.ERROR, {
          position: toast.POSITION.TOP_RIGHT
        })
      })
    }
  }, [product, mounter])

  const handleChangeYear = async (e: SelectChangeEvent<string>) => {
    let value = e.target.value
    setYear(value)
  }

  const getPrice = useCallback(async () => {
    if (product === '5') {
      await api.get(`table/search/trucks/${mounter}/${model}/${year}/`).then(async (response: any) => {
        if (response.status === 403 && userData?.plan_balance?.plan === '1') {
          return toast.error(response?.data?.content, { position: toast.POSITION.TOP_RIGHT })
        }
        const truckData = { user: response.data.user, content: { pricing: null, values: [response.data.content], hash: response.data.content.hash } }
        setCard('')
        setPrice(truckData)
        setCard('')
        await getUserData()
      }).catch((error) => {
        toast.error(DefaultMessage.ERROR, {
          position: toast.POSITION.TOP_RIGHT
        })
      })
    } else {
      await api.get(`table/search/model/price/${product}/${model}/${year}/`).then(async (response: any) => {
        if (response.status === 403 && userData?.plan_balance?.plan === '1') {
          return toast.error(response?.data?.content, { position: toast.POSITION.TOP_RIGHT })
        }
        setCard('')
        setPrice(response.data)
        setCard('')
        await getUserData()
      }).catch((error) => {
        toast.error(DefaultMessage.ERROR, {
          position: toast.POSITION.TOP_RIGHT
        })
      })
    }
  }, [product, model, year, mounter])

  const getUserData = useCallback(async () => {
    await api.get(`user/retrieve/${user?.id}/`).then((response: any) => {
      setUserData(response.data.content);
      setDisplayToCard('flex');
    }).catch((error) => {
      toast.error(DefaultMessage.ERROR, {
        position: toast.POSITION.TOP_RIGHT
      })
    })
  }, [user])

  const getPlateOrChassiPrice = async () => {
    setPricingTruckBus(undefined)

    const findBy = placa !== "" ? placa : chassi;

    await api.get(`/table/search/pricing/3/`, { "chassi_or_plate": findBy })
      .then((response: any) => {
        if (response.status === 200) {
          setIsLoading(false)
          setCard('')
          setPricing(response.data.content)
        }
        else if (response.status === 206) {
          setIsLoading(false)
          setCard('')
          setPricing(response.data.content)
        }
        toast.error(response.data.content, {
          position: toast.POSITION.TOP_RIGHT
        })
      }).catch((error: Error) => {
        setCard('3')
        toast.error(DefaultMessage.ERROR, {
          position: toast.POSITION.TOP_RIGHT
        })

      })
    await getUserData()
  }

  const getPlateOrChassiPriceTruckBus = async () => {
    setIsLoading(true)

    const findBy = placa !== "" ? placa : chassi;

    await api.post(`/check_view/checkview/query/5/`, { "chassi_or_plate": findBy })
      .then((response: any) => {
        if (response.status === 201) {
          setIsLoading(false)
          setCard('')
          setPricing(response.data.content)
          setPricingTruckBus(response.data.content)
        }
        else if (response.status === 400) {
          setIsLoading(false)
          setCard('5')
          toast.error(response.data.content.error, {
            position: toast.POSITION.TOP_RIGHT
          })
          setPlaca('')
          setChassi('')
        }
      }).catch((error) => {
        toast.error(DefaultMessage.ERROR, {
          position: toast.POSITION.TOP_RIGHT
        })
        setIsLoading(false)
        setCard('5')
      })
    await getUserData()
  }

  useEffect(() => {
    if (products?.length === 1) {
      handleCardSelected(products[0])
    }
    getUserData()
  }, [getUserData, products, user]);

  return (
    <Core withBlueBar withNavbar>
      <Grid
        container
        rowGap={2}
        columnGap={4}
        sx={{ zIndex: 2 }}
        alignItems="center"
        // top={smallCardButton ? 90 : 30}
        height={smallCardButton ? "auto" : "70vh"}
        position={smallCardButton ? "fixed" : "relative"}
        // position="relative"
        flexWrap={width < 607 && smallCardButton ? 'nowrap' : 'wrap'}
        maxHeight={width < 607 && smallCardButton ? "90px" : undefined}
        justifyContent={width < 607 && smallCardButton ? "flex-start" : "center"}
        top={smallCardButton ? '94px' : ''}
        style={width < 607 && smallCardButton ? { overflowX: 'scroll', overflowY: 'hidden', padding: '0 10px', paddingBottom: '10px' } : {}}
      >
        {
          products!.filter((item) => item === "1").length > 0
            ? <Grid
              // display={"flex"}
              // alignItems={"center"}
              // direction="column"
              textAlign="center"
            >
              <CardButton
                name="Trator"
                icon={<FaTractor />}
                color={
                  userData?.plan_balance.tr_balance !== null && userData?.plan_balance.tr_balance !== undefined
                    ? userData.plan_balance.plan === "2" && userData?.plan_balance.tr_balance < 1
                      ? colors.EskimoGray
                      : smallCardButton ? product === '1' ? colors.IceWhite : colors.WaterBlue : colors.WaterBlue
                    : smallCardButton ? product === '1' ? colors.IceWhite : colors.WaterBlue : colors.WaterBlue
                }
                backgroundColor={
                  userData?.plan_balance.tr_balance !== null && userData?.plan_balance.tr_balance !== undefined
                    ? userData.plan_balance.plan === "2" && userData?.plan_balance.tr_balance < 1
                      ? colors.FogGray
                      : smallCardButton ? product === '1' ? colors.WaterBlue : colors.LowBlue : colors.LoginBlue
                    : smallCardButton ? product === '1' ? colors.WaterBlue : colors.LowBlue : colors.LoginBlue
                }
                onClick={() => {
                  setIsLoading(true)
                  handleCardSelected('1')
                }}
                small={smallCardButton}
              />
              {
                !smallCardButton && !userData?.is_admin && !userData?.is_staff
                  ? userData?.plan_balance?.plan === '2'
                    ? <Chip
                      style={{ display: displayToCard }}
                      color={userData.plan_balance.tr_balance! < 1 ? "default" : "info"}
                      label={`${userData.plan_balance?.tr_balance} consultas restantes`}
                      sx={{ marginTop: 1 }}
                    />
                    : <Chip
                      style={{ display: displayToCard }}
                      color={userData?.plan_balance?.tr_balance! < (userData?.company_id ? userData?.plan_balance?.tr_count_company! : userData?.plan_balance?.tr_count_user)! ? "warning" : "info"}
                      label={`${userData?.company_id ? userData?.plan_balance?.tr_count_company : userData?.plan_balance?.tr_count_user} consultas realizadas de ${userData?.plan_balance?.tr_balance}`}
                      sx={{ marginTop: 1 }}
                    />
                  : <></>
              }
            </Grid>
            : <></>
        }
        {
          products!.filter((item) => item === "2").length > 0
            ? <Grid
              // display={"flex"}
              // alignItems={"center"}
              // direction="column"
              textAlign="center"
            >
              <CardButton
                name="Helicóptero"
                color={
                  userData?.plan_balance?.hp_balance !== null && userData?.plan_balance?.hp_balance !== undefined
                    ? userData.plan_balance?.plan === '2' && userData?.plan_balance?.hp_balance < 1
                      ? colors.EskimoGray
                      : smallCardButton ? product === '2' ? colors.IceWhite : colors.WaterBlue : colors.WaterBlue
                    : smallCardButton ? product === '2' ? colors.IceWhite : colors.WaterBlue : colors.WaterBlue
                }
                backgroundColor={
                  userData?.plan_balance?.hp_balance !== null && userData?.plan_balance?.hp_balance !== undefined
                    ? userData.plan_balance?.plan === '2' && userData?.plan_balance?.hp_balance < 1
                      ? colors.FogGray
                      : smallCardButton ? product === '2' ? colors.WaterBlue : colors.LowBlue : colors.LoginBlue
                    : smallCardButton ? product === '2' ? colors.WaterBlue : colors.LowBlue : colors.LoginBlue
                }
                icon={<FaHelicopter />}
                onClick={() => {
                  setIsLoading(true)
                  handleCardSelected('2')
                }}
                small={smallCardButton}
              />
              {
                !smallCardButton && !userData?.is_admin && !userData?.is_staff
                  ? userData?.plan_balance?.plan === '2'
                    ? <Chip
                      color={userData.plan_balance?.hp_balance! < 1 ? "default" : "info"}
                      label={`${userData.plan_balance?.hp_balance} consultas restantes`}
                      style={{ display: displayToCard }}
                      sx={{ marginTop: 1 }}
                    />
                    : <Chip
                      label={`${userData?.company_id ? userData?.plan_balance?.hp_count_company : userData?.plan_balance?.hp_count_user} consultas realizadas de ${userData?.plan_balance?.hp_balance}`}
                      color={userData?.plan_balance?.hp_balance! < (userData?.company_id ? userData?.plan_balance?.hp_count_company! : userData?.plan_balance?.hp_count_user)! ? "warning" : "info"}
                      style={{ display: displayToCard }}
                      sx={{ marginTop: 1 }}
                    />
                  : <></>
              }
            </Grid>
            : <></>
        }
        {
          products!.filter((item) => item === "3").length > 0
            ? <Grid
              // display={"flex"}
              // direction="column"
              textAlign="center"
            // alignItems={"center"}
            >
              <CardButton
                name="Semirreboque"
                color={
                  userData?.plan_balance.sr_balance !== null && userData?.plan_balance.sr_balance !== undefined
                    ? userData.plan_balance.plan === '2' && userData?.plan_balance.sr_balance < 1
                      ? colors.EskimoGray
                      : smallCardButton ? product === '3' ? colors.IceWhite : colors.WaterBlue : colors.WaterBlue
                    : smallCardButton ? product === '3' ? colors.IceWhite : colors.WaterBlue : colors.WaterBlue
                }
                backgroundColor={
                  userData?.plan_balance.sr_balance !== null && userData?.plan_balance.sr_balance !== undefined
                    ? userData.plan_balance.plan === '2' && userData?.plan_balance.sr_balance < 1
                      ? colors.FogGray
                      : smallCardButton ? product === '3' ? colors.WaterBlue : colors.LowBlue : colors.LoginBlue
                    : smallCardButton ? product === '3' ? colors.WaterBlue : colors.LowBlue : colors.LoginBlue
                }
                icon={<FaTrailer />}
                onClick={() => {
                  setIsLoading(true)
                  handleCardSelected('3')
                }}
                small={smallCardButton}
              />
              {
                !smallCardButton && !userData?.is_admin && !userData?.is_staff
                  ? userData?.plan_balance.plan === '2'
                    ? <Chip
                      color={userData.plan_balance.sr_balance! < 1 ? "default" : "info"}
                      label={`${userData.plan_balance.sr_balance} consultas restantes`}
                      style={{ display: displayToCard }}
                      sx={{ marginTop: 1 }}
                    />
                    : <Chip
                      label={`${userData?.company_id ? userData?.plan_balance.sr_count_company : userData?.plan_balance.sr_count_user} consultas realizadas de ${userData?.plan_balance.sr_balance}`}
                      color={userData?.plan_balance.sr_balance! < (userData?.company_id ? userData?.plan_balance.sr_count_company! : userData?.plan_balance.sr_count_user)! ? "warning" : "info"}
                      style={{ display: displayToCard }}
                      sx={{ marginTop: 1 }}
                    />
                  : <></>
              }
            </Grid>
            : <></>
        }
        {
          products!.filter((item) => item === "4").length > 0
            ? <Grid
              // display={"flex"}
              // direction="column"
              textAlign="center"
            // alignItems={"center"}
            >
              <CardButton
                name="Carroceria"
                color={
                  userData?.plan_balance.cr_balance !== null && userData?.plan_balance.cr_balance !== undefined
                    ? userData.plan_balance.plan === '2' && userData?.plan_balance.cr_balance < 1
                      ? colors.EskimoGray
                      : smallCardButton ? product === '4' ? colors.IceWhite : colors.WaterBlue : colors.WaterBlue
                    : smallCardButton ? product === '4' ? colors.IceWhite : colors.WaterBlue : colors.WaterBlue
                }
                backgroundColor={
                  userData?.plan_balance.cr_balance !== null && userData?.plan_balance.cr_balance !== undefined
                    ? userData.plan_balance.plan === '2' && userData?.plan_balance.cr_balance < 1
                      ? colors.FogGray
                      : smallCardButton ? product === '4' ? colors.WaterBlue : colors.LowBlue : colors.LoginBlue
                    : smallCardButton ? product === '4' ? colors.WaterBlue : colors.LowBlue : colors.LoginBlue
                }
                icon={<FaTruck />}
                onClick={() => {
                  setIsLoading(true)
                  handleCardSelected('4')
                }}
                small={smallCardButton}
              />
              {
                !smallCardButton && !userData?.is_admin && !userData?.is_staff
                  ? userData?.plan_balance.plan === '2'
                    ? <Chip
                      color={userData.plan_balance.cr_balance! < 1 ? "default" : "info"}
                      label={`${userData.plan_balance.cr_balance} consultas restantes`}
                      style={{ display: displayToCard }}
                      sx={{ marginTop: 1 }}
                    />
                    : <Chip
                      label={`${userData?.company_id ? userData?.plan_balance.cr_count_company : userData?.plan_balance.cr_count_user} consultas realizadas de ${userData?.plan_balance.cr_balance}`}
                      color={userData?.plan_balance.cr_balance! < (userData?.company_id ? userData?.plan_balance.cr_count_company! : userData?.plan_balance.cr_count_user!) ? "warning" : "info"}
                      style={{ display: displayToCard }}
                      sx={{ marginTop: 1 }}
                    />
                  : <></>
              }
            </Grid>
            : <></>
        }
        {
          products!.filter((item) => item === "5").length > 0
            ? <Grid
              textAlign="center"
            >
              <CardButton
                name="Caminhão e Ônibus"
                title="Caminhão e Ônibus"
                style={{ display: 'flex' }}

                color={
                  userData?.plan_balance.cm_balance !== null && userData?.plan_balance.cm_balance !== undefined
                    ? userData.plan_balance.plan === '2' && userData?.plan_balance.cm_balance < 1
                      ? colors.EskimoGray
                      : smallCardButton ? product === '5' ? colors.IceWhite : colors.WaterBlue : colors.WaterBlue
                    : smallCardButton ? product === '5' ? colors.IceWhite : colors.WaterBlue : colors.WaterBlue
                }
                backgroundColor={
                  userData?.plan_balance.cm_balance !== null && userData?.plan_balance.cm_balance !== undefined
                    ? userData.plan_balance.plan === '2' && userData?.plan_balance.cm_balance < 1
                      ? colors.FogGray
                      : smallCardButton ? product === '5' ? colors.WaterBlue : colors.LowBlue : colors.LoginBlue
                    : smallCardButton ? product === '5' ? colors.WaterBlue : colors.LowBlue : colors.LoginBlue
                }
                icon={
                  <div style={{ display: 'flex', flexDirection: 'column' }}><GiTruck style={{ marginTop: smallCardButton ? '5px' : '15px' }} /><GiBus style={{ marginTop: smallCardButton ? '-10px' : '-30px' }} /></div>
                }
                onClick={() => {
                  setIsLoading(true)
                  handleCardSelected('5')
                }}
                small={smallCardButton}
              />
              {
                !smallCardButton && !userData?.is_admin && !userData?.is_staff
                  ? userData?.plan_balance.plan === '2'
                    ? <Chip
                      color={userData.plan_balance.cm_balance! < 1 ? "default" : "info"}
                      label={`${userData.plan_balance.cm_balance} consultas restantes`}
                      style={{ display: displayToCard }}
                      sx={{ marginTop: 1 }}
                    />
                    : <Chip
                      label={`${userData?.company_id ? userData?.plan_balance.cm_count_company : userData?.plan_balance.cm_count_user} consultas realizadas de ${userData?.plan_balance.cm_balance}`}
                      color={userData?.plan_balance.cm_balance! < (userData?.company_id ? userData?.plan_balance.cm_count_company! : userData?.plan_balance.cm_count_user!) ? "warning" : "info"}
                      style={{ display: displayToCard }}
                      sx={{ marginTop: 1 }}
                    />
                  : <></>
              }
            </Grid>
            : <></>
        }
        {
          products!.filter((item) => item === "6").length > 0
            ? <Grid
              // display={"flex"}
              // direction="column"
              textAlign="center"
            // alignItems={"center"}
            >
              <CardButton
                name="Colheitadeira"
                title="Colheitadeira"
                style={{ display: 'flex' }}
                color={
                  userData?.plan_balance.hv_balance !== null && userData?.plan_balance.hv_balance !== undefined
                    ? userData.plan_balance.plan === '2' && userData?.plan_balance.hv_balance < 1
                      ? colors.EskimoGray
                      : smallCardButton ? product === '6' ? colors.IceWhite : colors.WaterBlue : colors.WaterBlue
                    : smallCardButton ? product === '6' ? colors.IceWhite : colors.WaterBlue : colors.WaterBlue
                }
                backgroundColor={
                  userData?.plan_balance.hv_balance !== null && userData?.plan_balance.hv_balance !== undefined
                    ? userData.plan_balance.plan === '2' && userData?.plan_balance.hv_balance < 1
                      ? colors.FogGray
                      : smallCardButton ? product === '6' ? colors.WaterBlue : colors.LowBlue : colors.LoginBlue
                    : smallCardButton ? product === '6' ? colors.WaterBlue : colors.LowBlue : colors.LoginBlue
                }
                icon={
                  userData?.plan_balance.hv_balance !== null && userData?.plan_balance.hv_balance !== undefined
                    ? userData.plan_balance.plan === '2' && userData?.plan_balance.hv_balance < 1
                      ? <div style={{ display: 'flex', flexDirection: 'column', opacity: "1" }}> {smallCardButton ? product === '6' ? <img width='30' height='28' src={colheitadeiraCinza} alt="harvester" /> : <img width='30' height='28' src={colheitadeiraCinza} alt="harvester" /> : <img width='96' height='90' src={colheitadeiraCinza} alt="harvester" />} </div>
                      : smallCardButton ? product === '6' ? <div style={{ display: 'flex', flexDirection: 'column', opacity: "1" }}> {smallCardButton ? product === '6' ? <img width='30' height='28' src={colheitadeiraBranca} alt="harvester" /> : <img width='30' height='28' src={colheitadeiraBranca} alt="harvester" /> : <img width='96' height='90' src={colheitadeiraBranca} alt="harvester" />} </div> : <div style={{ display: 'flex', flexDirection: 'column', opacity: "1" }}> {smallCardButton ? product === '6' ? <img width='30' height='28' src={colheitadeiraCinza} alt="harvester" /> : <img width='30' height='28' src={colheitadeira} alt="harvester" /> : <img width='96' height='90' src={colheitadeira} alt="harvester" />} </div> : <div style={{ display: 'flex', flexDirection: 'column', opacity: "1" }}> {smallCardButton ? product === '6' ? <img width='30' height='28' src={colheitadeiraCinza} alt="harvester" /> : <img width='30' height='28' src={colheitadeira} alt="harvester" /> : <img width='96' height='90' src={colheitadeira} alt="harvester" />} </div>
                    : smallCardButton ? product === '6' ? <div style={{ display: 'flex', flexDirection: 'column', opacity: "1" }}> {smallCardButton ? product === '6' ? <img width='30' height='28' src={colheitadeiraBranca} alt="harvester" /> : <img width='30' height='28' src={colheitadeiraBranca} alt="harvester" /> : <img width='96' height='90' src={colheitadeiraBranca} alt="harvester" />} </div> : <div style={{ display: 'flex', flexDirection: 'column', opacity: "1" }}> {smallCardButton ? product === '6' ? <img width='30' height='28' src={colheitadeiraCinza} alt="harvester" /> : <img width='30' height='28' src={colheitadeira} alt="harvester" /> : <img width='96' height='90' src={colheitadeira} alt="harvester" />} </div> : <div style={{ display: 'flex', flexDirection: 'column', opacity: "1" }}> {smallCardButton ? product === '6' ? <img width='30' height='28' src={colheitadeiraCinza} alt="harvester" /> : <img width='30' height='28' src={colheitadeira} alt="harvester" /> : <img width='96' height='90' src={colheitadeira} alt="harvester" />} </div>
                }
                onClick={() => {
                  setIsLoading(true)
                  handleCardSelected('6')
                }}
                small={smallCardButton}
              />
              {
                !smallCardButton && !userData?.is_admin && !userData?.is_staff
                  ? userData?.plan_balance.plan === '2'
                    ? <Chip
                      color={userData.plan_balance.hv_balance! < 1 ? "default" : "info"}
                      label={`${userData.plan_balance.hv_balance} consultas restantes`}
                      style={{ display: displayToCard }}
                      sx={{ marginTop: 1 }}
                    />
                    : <Chip
                      label={`${userData?.company_id ? userData?.plan_balance.hv_count_company : userData?.plan_balance.hv_count_user} consultas realizadas de ${userData?.plan_balance.hv_balance}`}
                      color={userData?.plan_balance.hv_balance! < (userData?.company_id ? userData?.plan_balance.hv_count_company! : userData?.plan_balance.hv_count_user!) ? "warning" : "info"}
                      style={{ display: displayToCard }}
                      sx={{ marginTop: 1 }}
                    />
                  : <div></div>
              }
            </Grid>
            : <div></div>
        }
      </Grid>
      {
        smallCardButton && !userData?.is_admin && !userData?.is_staff
          ? <Grid
            container
            className={styles.chip}
            // marginTop={25}
            style={{ marginTop: width <= 900 ? '18rem' : '14rem' }}
            marginBottom="3rem"
            textAlign="center"
          >
            {
              products!.filter((item) => item === "1").length > 0
                ? <Grid
                  item
                  md={12 / products!.length} sm={12} xs={12}
                >
                  {
                    userData?.plan_balance.plan === '2'
                      ? <Chip
                        icon={<FaTractor />}
                        color={userData.plan_balance.tr_balance! < 1 ? "default" : "info"}
                        label={`${userData.plan_balance.tr_balance} consultas restantes`}
                        style={{ display: displayToCard }}
                        sx={{ marginTop: 1 }}
                      />
                      : <Chip
                        label={`${userData?.company_id ? userData?.plan_balance.tr_count_company : userData?.plan_balance?.tr_count_user} consultas realizadas de ${userData?.plan_balance.tr_balance}`}
                        color={userData?.plan_balance.tr_balance! < (userData?.company_id ? userData?.plan_balance.tr_count_company! : userData?.plan_balance.tr_count_user)! ? "warning" : "info"}
                        style={{ display: displayToCard }}
                        sx={{ marginTop: 1 }}
                        icon={<FaTractor />}
                      />
                  }
                </Grid>
                : <></>
            }
            {
              products!.filter((item) => item === "2").length > 0
                ? <Grid
                  item
                  md={12 / products!.length} sm={12} xs={12}
                >
                  {
                    userData?.plan_balance.plan === '2'
                      ? <Chip
                        icon={<FaHelicopter />}
                        color={userData.plan_balance.hp_balance! < 1 ? "default" : "info"}
                        label={`${userData.plan_balance.hp_balance} consultas restantes`}
                        style={{ display: displayToCard }}
                        sx={{ marginTop: 1 }}
                      />
                      : <Chip
                        label={`${userData?.company_id ? userData?.plan_balance.hp_count_company : userData?.plan_balance.hp_count_user} consultas realizadas de ${userData?.plan_balance.hp_balance}`}
                        color={userData?.plan_balance.hp_balance! < (userData?.company_id ? userData?.plan_balance.hp_count_company! : userData?.plan_balance.hp_count_user!) ? "warning" : "info"}
                        style={{ display: displayToCard }}
                        icon={<FaHelicopter />}
                        sx={{ marginTop: 1 }}
                      />
                  }
                </Grid>
                : <></>
            }
            {
              products!.filter((item) => item === "3").length > 0
                ? <Grid
                  item
                  md={12 / products!.length} sm={12} xs={12}
                >
                  {
                    userData?.plan_balance.plan === '2'
                      ? <Chip
                        color={userData.plan_balance.sr_balance! < 1 ? "default" : "info"}
                        label={`${userData.plan_balance.sr_balance} consultas restantes`}
                        style={{ display: displayToCard }}
                        sx={{ marginTop: 1 }}
                        icon={<FaTrailer />}
                      />
                      : <Chip
                        label={`${userData?.company_id ? userData?.plan_balance.sr_count_company : userData?.plan_balance.sr_count_user} consultas realizadas de ${userData?.plan_balance.sr_balance}`}
                        color={userData?.plan_balance.sr_balance! < (userData?.company_id ? userData?.plan_balance.sr_count_company! : userData?.plan_balance.sr_count_user!) ? "warning" : "info"}
                        style={{ display: displayToCard }}
                        sx={{ marginTop: 1 }}
                        icon={<FaTrailer />}
                      />
                  }
                </Grid>
                : <></>
            }
            {
              products!.filter((item) => item === "4").length > 0
                ? <Grid
                  item
                  md={12 / products!.length} sm={12} xs={12}
                >
                  {
                    userData?.plan_balance.plan === '2'
                      ? <Chip
                        color={userData.plan_balance.cr_balance! < 1 ? "default" : "info"}
                        label={`${userData.plan_balance.cr_balance} consultas restantes`}
                        style={{ display: displayToCard }}
                        icon={<FaTruck />}
                        sx={{ marginTop: 1 }}
                      />
                      : <Chip
                        label={`${userData?.company_id ? userData?.plan_balance.cr_count_company : userData?.plan_balance.cr_count_user} consultas realizadas de ${userData?.plan_balance.cr_balance}`}
                        color={userData?.plan_balance.cr_balance! < (userData?.company_id ? userData?.plan_balance.cr_count_company! : userData?.plan_balance.cr_count_user!) ? "warning" : "info"}
                        style={{ display: displayToCard }}
                        icon={<FaTruck />}
                        sx={{ marginTop: 1 }}
                      />
                  }
                </Grid>
                : <></>
            }
            {
              products!.filter((item) => item === "5").length > 0
                ? <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12 / products!.length}
                >
                  {
                    userData?.plan_balance.plan === '2'
                      ? <Chip
                        icon={<div style={{ display: 'flex', flexDirection: 'column' }}><GiTruck /><GiBus /></div>}
                        sx={{ marginTop: 1 }}
                        style={{ display: displayToCard }}
                        label={`${userData.plan_balance.cm_balance} consultas restantes`}
                        color={userData.plan_balance.cm_balance! < 1 ? "default" : "info"}
                      />
                      : <Chip
                        icon={<div style={{ display: 'flex', flexDirection: 'column' }}><GiTruck /><GiBus /></div>}
                        sx={{ marginTop: 1 }}
                        style={{ display: displayToCard }}
                        color={userData?.plan_balance.cm_balance! < (userData?.company_id ? userData?.plan_balance.cm_count_company! : userData?.plan_balance.cm_count_user!) ? "warning" : "info"}
                        label={`${userData?.company_id ? userData?.plan_balance.cm_count_company : userData?.plan_balance.cm_count_user} consultas realizadas de ${userData?.plan_balance.cm_balance}`}
                      />
                  }
                </Grid>
                : <></>
            }
            {
              products!.filter((item) => item === "6").length > 0
                ? <Grid
                  item
                  sm={12}
                  xs={12}
                  md={12 / products!.length}
                >
                  {
                    userData?.plan_balance.plan === '2'
                      ? <Chip
                        icon={<div style={{ display: 'flex', flexDirection: 'column' }}><img width='10' height='10' src={userData?.plan_balance?.plan === '2' && userData?.plan_balance.hv_balance! < 1 ? colheitadeiraCinza : colheitadeiraBranca} alt="harvester" /></div>}
                        sx={{ marginTop: 1 }}
                        style={{ display: displayToCard }}
                        label={`${userData.plan_balance.hv_balance} consultas restantes`}
                        color={userData.plan_balance.hv_balance! < 1 ? "default" : "info"}
                      />
                      : <Chip
                        icon={<div style={{ display: 'flex', flexDirection: 'column' }}><img width='10' height='10' src={userData?.plan_balance.hv_balance! < (userData?.company_id ? userData?.plan_balance.hv_count_company! : userData?.plan_balance.hv_count_user!) ? colheitadeiraPreta : colheitadeiraBranca} alt="harvester" /></div>}
                        sx={{ marginTop: 1 }}
                        style={{ display: displayToCard }}
                        color={userData?.plan_balance.hv_balance! < (userData?.company_id ? userData?.plan_balance.hv_count_company! : userData?.plan_balance.hv_count_user!) ? "warning" : "info"}
                        label={`${userData?.company_id ? userData?.plan_balance.hv_count_company : userData?.plan_balance.hv_count_user} consultas realizadas de ${userData?.plan_balance.hv_balance}`}
                      />
                  }
                </Grid>
                : <div></div>
            }
          </Grid>
          : <div></div>
      }
      {
        card === '3' && userData?.can_query_chassi_or_plate && !isLoading ?
          <Grid
            container
            direction="column"
            textAlign="center"
            alignContent="center"
            paddingX={width > 600 ? "30%" : "5%"}
            marginTop={smallCardButton && !userData?.is_admin && !userData?.is_staff ? 5 : 25}
            gap={2}
            style={
              !userData?.is_admin && !userData?.is_staff ?
                // && userData?.plan_balance?.plan !== null ?
                {
                  marginTop: window.innerWidth < 990 ? '0rem' : '0rem',
                  marginBottom: window.innerWidth < 990 ? '7rem' : '2rem'
                } :
                {
                  marginTop: window.innerWidth <= 900 ? '18rem' : '14rem',
                  marginBottom: window.innerWidth < 990 ? '8rem' : '4rem'
                }
            }
          >
            <div className={styles.optionsCheckContainer}>
              <div className={styles.optionCheck} onClick={() => changeSearchOption("brandModelYear")}>
                <label style={{ minWidth: '100%', width: 'max-content', display: 'flex', alignItems: 'center', fontSize: '.9rem' }}>
                  <CustomRadio
                    checked={(selectedValue === "true" || selectedValue === null || selectedValue === undefined) ? true : false}
                    value="brandModelYear"
                    name="radio-buttons"
                    inputProps={{ 'aria-label': 'A' }}
                    style={{ pointerEvents: 'none' }}
                  />
                  MARCA/MODELO/ANO
                </label>
              </div>
              <div className={styles.optionCheck} onClick={() => changeSearchOption("plateOrChassi")}>
                <label style={{ width: '100%', display: 'flex', alignItems: 'center', fontSize: '.9rem' }}>
                  <CustomRadio
                    checked={selectedValue === "false" ? true : false}
                    value="plateOrChassi"
                    name="radio-buttons"
                    inputProps={{ 'aria-label': 'A' }}
                    style={{ pointerEvents: 'none' }}
                  />
                  PLACA/CHASSI
                </label>
              </div>
            </div>
          </Grid> : <></>
      }
      {
        card === '5' && userData?.can_query_chassi_or_plate_bus_truck && !isLoading ?
          <Grid
            container
            direction="column"
            textAlign="center"
            alignContent="center"
            paddingX={width > 600 ? "30%" : "5%"}
            marginTop={smallCardButton && !userData?.is_admin && !userData?.is_staff ? 5 : 25}
            gap={2}
            style={
              !userData?.is_admin && !userData?.is_staff ?
                // && userData?.plan_balance?.plan !== null ?
                {
                  marginTop: window.innerWidth < 990 ? '0rem' : '0rem',
                  marginBottom: window.innerWidth < 990 ? '7rem' : '2rem'
                } :
                {
                  marginTop: window.innerWidth <= 900 ? '18rem' : '14rem',
                  marginBottom: window.innerWidth < 990 ? '8rem' : '4rem'
                }
            }
          >
            <div className={styles.optionsCheckContainer}>
              <div className={styles.optionCheck} onClick={() => changeSearchOptionTruckBus("brandModelYearTruckBus")}>
                <label style={{ minWidth: '100%', width: 'max-content', display: 'flex', alignItems: 'center', fontSize: '.9rem' }}>
                  <CustomRadio
                    checked={(selectedValueTruckBus === "true" || selectedValueTruckBus === null || selectedValueTruckBus === undefined) ? true : false}
                    value="brandModelYearTruckBus"
                    name="radio-buttons-truckBus"
                    inputProps={{ 'aria-label': 'A' }}
                    style={{ pointerEvents: 'none' }}
                  />
                  MARCA/MODELO/ANO
                </label>
              </div>
              <div className={styles.optionCheck} onClick={() => changeSearchOptionTruckBus("plateOrChassiTruckBus")}>
                <label style={{ width: '100%', display: 'flex', alignItems: 'center', fontSize: '.9rem' }}>
                  <CustomRadio
                    checked={selectedValueTruckBus === "false" ? true : false}
                    value="plateOrChassiTruckBus"
                    name="radio-buttons-truckBus"
                    inputProps={{ 'aria-label': 'A' }}
                    style={{ pointerEvents: 'none' }}
                  />
                  PLACA/CHASSI
                </label>
              </div>
            </div>
          </Grid> : <></>
      }
      {
        smallCardButton
          ? !price
            ? !pricing
              ? !isLoading
                ? (!checkedTruckBus && card === '5' && userData?.can_query_chassi_or_plate_bus_truck && (selectedValueTruckBus === 'false'))
                  ?
                  <Grid
                    container
                    direction="column"
                    textAlign="center"
                    alignContent="center"
                    paddingX={width > 600 ? "30%" : "5%"}
                    marginTop='2rem'
                    gap={2}
                  >
                    <FormControl fullWidth>
                    </FormControl>
                    <FormControl fullWidth>
                      <p style={{ color: '#006991', marginBottom: '.5rem' }}>INFORME A PLACA OU CHASSI</p>
                      <TextField
                        id="outlined-basic"
                        // label={!isFocusedChassi ? 'INFORME A PLACA OU CHASSI AQUI' : ''}
                        placeholder="Informe a placa ou chassi aqui"
                        value={chassi}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChassiChange(e)}
                        variant="outlined"
                        InputLabelProps={{
                          style: { color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', position: 'absolute', top: '-15px' }
                        }}
                        inputProps={{
                          style: {
                            textAlign: 'center'
                          }
                        }}
                      />
                    </FormControl>
                    <Button
                      className="btn"
                      variant="contained"
                      size="large"
                      color="secondary"
                      onClick={getPlateOrChassiPriceTruckBus}
                      disabled={placa === "" && chassi === ""}
                    >
                      Buscar
                    </Button>
                  </Grid>
                  :
                  (!checked && card === '3' && userData?.can_query_chassi_or_plate && (selectedValue === 'false'))
                    ? <Grid
                      container
                      direction="column"
                      textAlign="center"
                      alignContent="center"
                      paddingX={width > 600 ? "30%" : "5%"}
                      marginTop='2rem'
                      gap={2}
                    >
                      <FormControl fullWidth>
                      </FormControl>
                      <FormControl fullWidth>
                        <p style={{ color: '#006991', marginBottom: '.5rem' }}>INFORME A PLACA OU CHASSI</p>
                        <TextField
                          id="outlined-basic"
                          // label={!isFocusedChassi ? 'INFORME A PLACA OU CHASSI AQUI' : ''}
                          placeholder="Informe a placa ou chassi aqui"
                          value={chassi}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChassiChange(e)}
                          variant="outlined"
                          InputLabelProps={{
                            style: { color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', position: 'absolute', top: '-15px' }
                          }}
                          inputProps={{
                            style: {
                              textAlign: 'center'
                            }
                          }}
                        />
                      </FormControl>
                      <Button
                        className="btn"
                        variant="contained"
                        size="large"
                        color="secondary"
                        onClick={getPlateOrChassiPrice}
                        disabled={placa === "" && chassi === ""}
                      >
                        Buscar
                      </Button>
                    </Grid>
                    :
                    <Grid
                      container
                      direction="column"
                      textAlign="center"
                      alignContent="center"
                      paddingX={width > 600 ? "30%" : "5%"}
                      // marginTop={smallCardButton && !userData?.is_admin && !userData?.is_staff ? '2rem' : width <= 900 ? '17rem' : '12rem'}
                      gap={2}
                      style={
                        (card === '3' || card === '5') && userData?.can_query_chassi_or_plate && userData?.can_query_chassi_or_plate_bus_truck && !isLoading ?
                          {
                            marginTop: '0rem',
                          } :
                          !userData?.is_admin && !userData?.is_staff ?
                            {
                              marginTop: '0rem',
                            } :
                            {
                              marginTop: window.innerWidth <= 900 ? '18rem' : '14rem',
                            }
                      }
                    >
                      <FormControl fullWidth>
                        <Select
                          labelId="mounter-label"
                          id="mounter-select"
                          value={mounter}
                          label="Marca"
                          onChange={handleChangeMounter}
                          variant="outlined"
                        // style={{
                        //   marginTop: width >= 901 ? '' : '17rem'
                        // }}
                        >
                          <MenuItem key="mounter-key" value="mounter">SELECIONE UMA MARCA</MenuItem>
                          {
                            mounters?.content.map((item, index) => <MenuItem key={`mounter-${index}`} value={item?.mounter_code ? item?.mounter_code : item?.codigo}>{item?.mounter ? item?.mounter : item?.nome}</MenuItem>)
                          }
                        </Select>
                      </FormControl>
                      <FormControl fullWidth>
                        <Select
                          value={model}
                          label="Modelo"
                          id="model-select"
                          variant="outlined"
                          labelId="model-label"
                          onChange={handleChangeModel}
                          disabled={mounter === "mounter"}
                        >
                          <MenuItem key="model-key" value="model">SELECIONE UM MODELO</MenuItem>
                          {
                            models?.content?.map((item, index) => <MenuItem key={`model-${index}`} value={item.model_code || item.codigo}>{item?.description || item.nome}</MenuItem>)
                          }
                        </Select>
                      </FormControl>
                      <FormControl fullWidth>
                        <Select
                          labelId="year-label"
                          id="year-select"
                          value={year}
                          label="Ano"
                          onChange={handleChangeYear}
                          variant="outlined"
                          disabled={model === "model"}
                        >
                          <MenuItem key="year-key" value="year">SELECIONE UM ANO</MenuItem>
                          {
                            years?.content.years.map((item: any, index) => (
                              <MenuItem
                                value={item.codigo || item}
                                key={`year-${index}`}
                              >
                                {
                                  isNaN(Math.trunc(parseInt(item.nome || item))) ? item.nome || item : Math.trunc(parseInt(item.nome || item))
                                }
                              </MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                      <Button
                        className="btn"
                        variant="contained"
                        size="large"
                        color="secondary"
                        onClick={getPrice}
                        disabled={year === "year"}
                      >
                        Buscar
                      </Button>
                    </Grid>
                : <Grid
                  container
                  direction="column"
                  textAlign="center"
                  alignContent="center"
                  paddingX={width > 600 ? "30%" : "10%"}
                  marginTop={smallCardButton && !userData?.is_admin && !userData?.is_staff ? 5 : 25}
                  gap={2}
                >
                  <CircularProgress size={100} />
                </Grid>
              :
              pricingTruckBus != undefined ?
                <Grid
                  container
                  direction="column"
                  textAlign="center"
                  alignItems="center"
                  alignContent="center"
                  marginY={!userData?.is_admin && !userData?.is_staff ? 5 : 25}
                  gap={6}
                >
                  {pricingTruckBus && (
                    <div>
                      <InfoPrice
                        hash={pricingTruckBus.hash}
                        reference={componentRef}
                        axis={pricingTruckBus.response.AGREGADOS?.nr_eixos}
                        city={pricingTruckBus.response.AGREGADOS?.nm_municipio}
                        year_fabrication={pricingTruckBus.response.AGREGADOS?.nr_anofabricacao}
                        plate={pricingTruckBus.response.PLACA}
                        product={pricingTruckBus.response.DECODIFICADOR?.DsCategoria}
                        brand_model={pricingTruckBus.response.DECODIFICADOR?.DsModelo}
                        chassi={pricingTruckBus.response.CHASSI}
                        fabricator={pricingTruckBus.response.DECODIFICADOR?.DsMarca}
                        state={pricingTruckBus.response.AGREGADOS?.sg_uffaturado}
                        type_vehicle={pricingTruckBus.response.AGREGADOS?.nm_tipoveiculo}
                        region={pricingTruckBus.response.DECODIFICADOR?.DsRegiao}
                        updated_at={pricing?.updated_at}
                        date_time={pricing?.created_at}
                        valuesFipe={pricingTruckBus.response.DECODIFICADOR?.PrecificadorI}
                      />
                    </div>
                  )}

                  <Grid
                    container
                    justifyContent="center"
                    gap={2}
                  >
                    <Button
                      className="btn-inverse"
                      variant="contained"
                      size="large"
                      color="secondary"
                      onClick={() => handlePrint()}
                    >
                      <ShareIcon /> &nbsp; Compartilhar
                    </Button>
                    <Button
                      className="btn"
                      variant="contained"
                      size="large"
                      color="secondary"
                      onClick={() => {
                        setCard('5')
                        setPricing(undefined)
                        setPlaca('')
                        setChassi('')
                      }}
                    >
                      <ArticleIcon /> &nbsp; Nova consulta
                    </Button>
                  </Grid>
                </Grid>
                :
                <Grid
                  container
                  direction="column"
                  textAlign="center"
                  alignItems="center"
                  alignContent="center"
                  marginY={!userData?.is_admin && !userData?.is_staff ? 5 : 25}
                  gap={6}
                >
                  <InfoPrice
                    // @ts-ignore
                    reference={componentRef}
                    axis={pricing.pricing?.axis}
                    city={pricing.pricing?.city}
                    year_fabrication={pricing.pricing?.year_fabrication}
                    plate={pricing.pricing?.plate}
                    product={pricing.pricing?.product}
                    brand_model={pricing.pricing?.brand_model}
                    chassi={pricing.pricing?.chassi}
                    fabricator={pricing.pricing?.fabricator}
                    state={pricing.pricing?.state}
                    type_vehicle={pricing.pricing?.type_vehicle}
                    region={pricing.pricing?.region}
                    values={pricing.values}
                    hash={pricing.hash}
                    updated_at={pricing?.values ? pricing?.values[0]?.updated_at : undefined}
                    date_time={pricing?.values ? pricing?.values[0]?.date_time : undefined}
                    messageProps={pricing?.message}
                  />
                  <Grid
                    container
                    justifyContent="center"
                    gap={2}
                  >
                    <Button
                      className="btn-inverse"
                      variant="contained"
                      size="large"
                      color="secondary"
                      onClick={() => handlePrint()}
                    >
                      <ShareIcon /> &nbsp; Compartilhar
                    </Button>
                    <Button
                      className="btn"
                      variant="contained"
                      size="large"
                      color="secondary"
                      onClick={() => { setPricing(undefined); setCard('3'); setPlaca(''); setChassi('') }}
                    >
                      <ArticleIcon /> &nbsp; Nova consulta
                    </Button>
                  </Grid>
                </Grid>
            : <Grid
              container
              direction="column"
              textAlign="center"
              alignItems="center"
              alignContent="center"
              marginY={!userData?.is_admin && !userData?.is_staff ? 5 : 25}
              gap={6}
            >
              <InfoPrice2
                // @ts-ignore
                reference={componentRef}
                type={price.content.values[0].type}
                year={price.content.values[0].year}
                hash={price.content.hash}
                value={price.content.values[0].value}
                model={price.content.values[0].description || price.content.values[0].model}
                mounter={price.content.values[0].mounter}
                date_time={price.content.values[0].date_time}
                updated_at={price.content.values[0].updated_at}
                somos_code={price.content.values[0].somos_code}
                codigo_fipe={price.content.values[0].codigo_fipe}
              />
              <Grid
                container
                justifyContent="center"
                gap={2}
              >
                <Button
                  className="btn-inverse"
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={() => handlePrint()}
                >
                  <ShareIcon /> &nbsp; Compartilhar
                </Button>
                <Button
                  className="btn"
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={() => { handleCardSelected(product!) }}
                >
                  <ArticleIcon /> &nbsp; Nova consulta
                </Button>
              </Grid>
            </Grid>
          : <></>
      }
    </Core>
  )
}