import { Avatar, Card, Collapse, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material"
import { Fragment } from "react"
import { FaHelicopter, FaTractor, FaTrailer } from "react-icons/fa"
import { GiBus, GiTruck } from "react-icons/gi"
import { RiTruckFill } from "react-icons/ri"
import { ConsultValuesProps } from "../../../pages/User/types"
import { v4 as uuidv4 } from 'uuid';

type ListItemValuesProps = {
  index: number,
  itemType: string
  indexSelected: number
  plate?: string
  chassi?: string
  model?: string
  email: string
  created_at: string
  items: ConsultValuesProps[]
  setIndexSelected: (value: React.SetStateAction<number>) => void
  pricing: boolean
}

export default function ListItemValues({ index, itemType, indexSelected, plate, chassi, model, email, created_at, items, setIndexSelected, pricing }: ListItemValuesProps): JSX.Element {
  return (
    <Card key={`fiv-list-item-${index}`} style={{ marginTop: 20 }}>
      <ListItemButton onClick={() => { setIndexSelected(index) }}>
        <ListItemIcon>
          <Avatar>
            {
              itemType === "1"
                ? <FaTractor />
                : itemType === "2"
                  ? <FaHelicopter />
                  : itemType === "3"
                    ? <FaTrailer />
                    : itemType === "4"
                      ? <RiTruckFill />
                      : itemType === "5"
                        ? <div style={{ display: 'flex', flexDirection: 'column' }}><GiTruck /><GiBus /></div>
                        : <img width="20" height="20" src="https://img.icons8.com/material-rounded/96/harvester.png" alt="harvester" />
            }
          </Avatar>
        </ListItemIcon>
        <ListItemText
          key={`fiv-list-item-${index}`}
          primary={
            <Fragment>
              <Typography
                variant="body1"
                color="text.primary"
              >
                {
                  pricing ?
                    <>
                      <strong>Placa: </strong>{plate}
                      <br />
                      <strong>Chassi: </strong>{chassi}
                    </>
                    :
                    <>
                      <strong>Modelo: </strong>{model}
                    </>
                }

              </Typography>
            </Fragment>
          }
          secondary={
            <Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body1"
                color="text.primary"
              >
                {`Consulta realizada por ${email} no dia ${created_at}`}
              </Typography>
            </Fragment>
          }
        />
      </ListItemButton>
      <Collapse in={index === indexSelected} timeout="auto" unmountOnExit key={index}>
        <List component="div" key={`list-${index}`} disablePadding>
          {
            items.map((item) =>
              <ListItem sx={{ pl: 4 }} key={uuidv4()} >
                <ListItemText
                  primary={
                    <Fragment>
                      <Typography
                        variant="body1"
                        color="text.primary"
                      >
                        <strong>Cod: </strong>{item.somos_code || item.codigo_fipe || item.DsCodigo}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="text.primary"
                      >
                        <strong>Marca: </strong>{item.mounter || item.DsMarca}
                      </Typography>
                      {
                        pricing ?
                          <Typography
                            variant="body1"
                            color="text.primary"
                          >
                            <strong>Modelo: </strong>{item.model || item.DsModelo}
                          </Typography>
                          :
                          <></>
                      }
                      {item.NuValor ? <></> : <Typography
                        variant="body1"
                        color="text.primary"
                      >
                        <strong>Ano: </strong>{item.year}
                      </Typography>}
                      <Typography variant="body1" color="text.primary">
                        {item.NuValor !== undefined && (
                          <>
                            <strong>Valor: </strong>R$ {item.NuValor.toLocaleString()}
                          </>
                        )}
                        {item?.type && item?.value !== undefined && (
                          <>
                            <strong>Valor: </strong>
                            {item?.type === "5" ? '' : 'R$ '}
                            {item?.value.toLocaleString()}
                          </>
                        )}
                      </Typography>
                    </Fragment>
                  }
                />
                <Divider />
              </ListItem>
            )
          }
        </List>
      </Collapse>
    </Card>
  )
}