import { Link } from "react-router-dom"
import { InfoPriceStyles } from "./styles"
import React, { PureComponent } from "react"
import { ConsultValuesFipeProps, ConsultValuesProps } from "../../pages/User/types"

interface InfoPriceProps {
  reference: React.RefObject<HTMLDivElement>
  axis?: number
  city?: string
  year_fabrication?: number
  plate?: string
  product?: string
  brand_model?: string
  chassi?: string
  fabricator?: string
  state?: string
  type_vehicle?: string
  region?: string
  values?: Array<ConsultValuesProps>
  hash?: string
  updated_at?: string
  date_time?: string
  messageProps?: string
  codeFipe?: string
  valuesFipe?: Array<ConsultValuesFipeProps>
}

const somosLogo = require("../../images/horizontalLogo.png")

export default class InfoPrice extends PureComponent<InfoPriceProps> {
  render(): React.ReactNode {
    const { reference, hash, axis, city, year_fabrication, plate, product, brand_model, chassi, fabricator, state, type_vehicle, region, values, updated_at, date_time, messageProps, codeFipe, valuesFipe } = this.props
    const message = "*O preço do referido implemento é sem pneus, não leva em consideração os tipos de acessórios adicionais, tipo de suspensão, condições de pintura e lataria, histórico de acidente/incidente, histórico de manutenção e comissão de vendas.";
    return (
      <InfoPriceStyles id="report" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} ref={reference}>
        <div id="report-logo">
          <Link to="https://tabelasomos.com.br">
            <img src={somosLogo} width={270} alt="Logo" />
          </Link>
        </div>
        <strong id="info-message-and-hash" className="hash">Hash de autenticidade: {hash}</strong>
        <table id="report-body-card">
          <tbody>
            <tr>
              <td className="no-border">PLACA</td>
              <td>{plate || '-'}</td>
            </tr>
            <tr>
              <td className="no-border">CHASSI</td>
              <td>{chassi || '-'}</td>
            </tr>
            <tr>
              <td className="no-border">FABRICANTE</td>
              <td>{fabricator || '-'}</td>
            </tr>
            <tr>
              <td className="no-border">PRODUTO</td>
              <td>{product || '-'}</td>
            </tr>
            <tr>
              <td className="no-border">MODELO</td>
              <td>{brand_model || '-'}</td>
            </tr>
            <tr>
              <td className="no-border">ANO</td>
              <td>{year_fabrication || '-'}</td>
            </tr>
            <tr>
              <td className="no-border">EIXOS</td>
              <td>{axis || '-'}</td>
            </tr>
            <tr>
              <td className="no-border">TIPO DE VEÍCULO</td>
              <td>{type_vehicle || '-'}</td>
            </tr>
            <tr>
              <td className="no-border">FABRICADO NA REGIÃO</td>
              <td>{region || '-'}</td>
            </tr>
            <tr>
              <td className="no-border">FABRICADO NA UF</td>
              <td>{state || '-'}</td>
            </tr>
            <tr>
              <td className="no-border">FABRICADO NA CIDADE</td>
              <td>{city || '-'}</td>
            </tr>
            {valuesFipe&& 
              <tr >
               <td className="price" style={{ backgroundColor: "#006991", color: "white", textAlign: 'center' }} colSpan={2}>
                 PREÇOS FIPE
               </td>
             </tr>
        }
        {valuesFipe?.map((item, subIndex) => {
              return (
                <React.Fragment key={subIndex}>
                  <tr>
                    <td className="no-border" style={{ backgroundColor: "#c9f0ff" }}>CÓDIGO FIPE</td>
                    <td style={{ backgroundColor: "#c9f0ff" }}>{item.DsCodigo}</td>
                  </tr>
                  <tr>
                    <td className="no-border">MODELO</td>
                    <td>{item.DsModelo}</td>
                  </tr>
                  <tr>
                    <td className="no-border">COMBUSTÍVEL</td>
                    <td>{item.DsCombustivel}</td>
                  </tr>
                  <tr>
                    <td className="no-border">PREÇO MÉDIO</td>
                    <td>{`R$ ${item.NuValor.toLocaleString('pt-BR', { style: 'decimal' })}*`}</td>
                  </tr>
               </React.Fragment>
              )})}
            {
            
              values ?
                <tr>
                  <td className="price" style={{ backgroundColor: "#006991", color: "white", textAlign: 'center' }} colSpan={2}>PREÇOS POSSÍVEIS NA TABELA SOMOS</td>
                </tr> : <></>
            }

            {
              values ?
                values?.map((item: ConsultValuesProps, index: number) => {
                  return (
                    <>
                      <tr>
                        <td className="no-border" style={{ backgroundColor: "#c9f0ff" }}>CÓDIGO SOMOS</td>
                        <td style={{ backgroundColor: "#c9f0ff" }}>{item.somos_code}</td>
                      </tr>
                      <tr>
                        <td className="no-border">DESCRIÇÃO</td>
                        <td>{item.description}</td>
                      </tr>
                      <tr>
                        <td className="no-border">PREÇO MÉDIO</td>
                        <td>{typeof item.value === 'string' ? item.value : `R$ ${item.value.toLocaleString('pt-BR', { style: 'decimal' })}*`}</td>
                      </tr>
                    </>
                  )
                })
                : <></>
            }
            {
              (values || valuesFipe) ?
                <>
                  <tr>
                    <td className="no-border" style={{ backgroundColor: "#a3e5ff" }}>ATUALIZADO EM</td>
                    <td style={{ backgroundColor: "#a3e5ff" }}>{updated_at}</td>
                  </tr>
                  <tr>
                    <td className="no-border" style={{ backgroundColor: "#a3e5ff" }}>DATA DA CONSULTA</td>
                    <td style={{ backgroundColor: "#a3e5ff" }}>{date_time}</td>
                  </tr>
                </>
                : <></>
            }
          </tbody>
        </table>

        <i id="info-message-and-hash" style={window.innerWidth < 600 ? { width: '95vw', textAlign: 'center' } : { width: "50vw", textAlign: 'center' }}>{messageProps ? messageProps : valuesFipe ? "" : message}</i>

        <p id="report-link-site">
          <Link to="https://tabelasomos.com.br">www.<strong>TABELASOMOS</strong>.com.br</Link>
        </p>
      </InfoPriceStyles>
    )
  }
}